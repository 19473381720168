<template>

  <div class="row">
    <b-modal :id="formId" :size="formWidth">
      <template #modal-title>
        <div v-if="formState=='Update'">Update Transactions #{{ formData.id }}</div>
        <div v-if="formState=='Create'">Create Transactions</div>
      </template>

      <EditTransactions
          v-if="formState=='Update'"
          :key="formKey"
          :data="formData"
          :gridApi="formGridApi"
          :lignesData="lignesData"
          :modalFormId="formId"
          :terminalsData="terminalsData"
          @close="closeForm"
      />


      <CreateTransactions
          v-if="formState=='Create'"
          :key="formKey"
          :gridApi="formGridApi"
          :lignesData="lignesData"
          :modalFormId="formId"
          :terminalsData="terminalsData"
          @close="closeForm"
      />

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>


    <div class="col-sm-12">
      <AgGridTable
          :key="tableKey"
          :cacheBlockSize="cacheBlockSize"
          :columnDefs="columnDefs"
          :extrasData="extrasData"
          :maxBlocksInCache="maxBlocksInCache"
          :pagination="pagination"
          :paginationPageSize="paginationPageSize"
          :rowData="rowData"
          :rowModelType="rowModelType"
          :url="url"
          className="ag-theme-alpine"
          domLayout='autoHeight'
          rowSelection="multiple"
          @gridReady="onGridReady"

      >
        <template #header_buttons>
        </template>

      </AgGridTable>

    </div>
  </div>
</template>


<script>

export default {
  name: 'TransactionsView',
  components: {
    DataTable: () => import( "@/components/DataTable.vue"),
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    DataModal: () => import("@/components/DataModal.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue"),
    CustomSelect: () => import("@/components/CustomSelect.vue"),
    CustomFiltre: () => import("@/components/CustomFiltre.vue"),
  },
  props: {
    carte: {
      default: 0
    }
  },
  data() {

    return {
      formId: "transactions",
      formState: "",
      formData: {},
      formWidth: 'lg',
      formGridApi: {},
      formKey: 0,
      tableKey: 0,
      url: 'http://127.0.0.1:8000/api/transactions-Aggrid1',
      table: 'transactions',
      lignesData: [],
      terminalsData: [],
      requette: 2,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 10,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
    }
  },

  computed: {
    routeData: function () {
      let router = {meta: {}}
      if (window.router) {
        try {
          router = window.router;
        } catch (e) {
        }
      }


      return router
    },
    taille: function () {
      let result = 'col-sm-12'
      if (this.filtre) {
        result = 'col-sm-9'
      }
      return result
    },
    extrasData: function () {
      let retour = {};
      let params = {};
      if (this.carte) {
        params['card_no'] = {values: [this.carte], filterType: "set"};

      } else {

      }
      retour["baseFilter"] = params;

      return retour;
    },
  },
  watch: {
    'routeData': {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null)
        this.gridApi.refreshServerSide()
      },
      deep: true
    },
  },
  created() {
    // this.url = this.axios.defaults.baseURL + '/useCase?action=Aggrid1Transaction',
    this.url = this.axios.defaults.baseURL + '/api/transactions-Aggrid1',
        this.formId = this.table + "_" + Date.now()
    this.rowBuffer = 0;
    this.rowModelType = 'serverSide';
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;

  },
  beforeMount() {
    this.columnDefs =
    [

{
    field: 'transactions.id',
    headerName: "",
    suppressCellSelection: true,
    hide: true
},
    {
        field: "punch_time",
        sortable: true,
        filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
        headerName: 'date',
    },
    {
        headerName: 'terminal',
        field: 'pointeuses.libelle',
        join: {
            table: 'pointeuses',
            tableAlias: 'pointeuses',
            champ1: 'transactions.terminal_alias',
            champ2: 'pointeuses.code',
            operateur: '=',
        }
    },


];


  },
  mounted() {
    if (this.requette > 0) {
      // this.$store.commit('setIsLoading', true)
    }

    this.getlignes();
    this.getterminals();

  },
  methods: {
    closeForm() {
      try {
        this.gridApi.refreshServerSide()
      } catch (e) {

      }
    },
    openCreate() {
      this.showForm('Create', {}, this.gridApi)
    },
    showForm(type, data, gridApi, width = 'lg') {
      this.formKey++
      this.formWidth = width
      this.formState = type
      this.formData = data
      this.formGridApi = gridApi
      this.$bvModal.show(this.formId)
    },
    onGridReady(params) {
      console.log('on demarre', params)
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false
    },
    getlignes() {
      this.axios.get('/api/lignes').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.lignesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getterminals() {
      this.axios.get('/api/terminals').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.terminalsData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

  }
}
</script>
